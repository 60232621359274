import './App.css';

function App() {
  return (
    <div className="App App-link">
      This is the dev env !
    </div>
  );
}

export default App;
